.header{
	top: 0;
	left: 0;
	right: 0;
	transition: .3s ease-in-out;
	position: fixed;
	z-index: 10!important;
	background-color: #FFF;
	.navbar-item, .navbar-link{
		color: $primary-dark!important;
		&:hover, &:active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active{
			color: #0B1829!important;
			font-weight: 700;
		}
		.navbar-link:not(.is-arrowless)::after{
			border-color: $primary;
		}
	}
	a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active{
		background-color: transparent!important;
	}
	.navbar-item.has-dropdown{
		position: unset;
	}
	.navbar-item.logo{
		img{
			max-height: 75px;
		}
	}
	.navbar-end{
		margin-right: auto;
		margin-left: 3rem;
	}
	.navbar-dropdown{
		.card{
			box-shadow: none;
			border-radius: 0px;
			&:hover{
				border:2px solid $red;
				a {
					.card-content{
						color: $red!important;
					}
				}
			}
			figure{
				position: relative;
				padding-top: 70%;
				display: block;
				img{
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					max-height: 100%;
					border-radius: 0px;
				}
			}
		}
	}
}
.fixed_nav.scroll{
	box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.6);
}
/*--------------Slideshoe section---*/
.slideshow{
	.button.next-btn , .button.prev-btn{
		position: absolute;
		top: 50%;
		border-radius: 100%;
		border:0px;
		width: 40px;
		height: 40px;
		transition: .4s ease-in-out;
		&:hover{
			background: $secondary;
			color: white!important;
			transition: .4s ease-in-out;
		}
	}
	.button.next-btn{
		right:2%;
	}
	.button.prev-btn{
		left:2%;
		z-index: 1;
	}
	.slick-slide{
		figure{
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: -1;
			img{
				width: 100%;
				height: 100%!important;
			}
		}
		.item{
			width: 100%;
			&::after{
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				width: 39%;
				height:100%;
				/*background-image: url('/front/images/bg-header.svg');*/
				fill: rgba(128, 128, 128, 0.10);
				backdrop-filter: blur(4px);
				background-color: rgba(255,255,255,.2);
			}
			&::before{
				position: absolute;
				content: "";
				top: 0;
				left: 39%;
				width: 10%;
				height: 100%;
				background-color: rgba(255,255,255,.2);
				-webkit-mask: linear-gradient(to top right, #000 50%, transparent 50%);
				backdrop-filter: blur(4px);
			}
			.description{
				position: relative;
				z-index: 1;
				padding-left: 5rem;
			}
		}
	}
	.slick-dots{
		bottom: 35%;
		left: 10%;
		text-align: left;
		li{
			width: 30px;
			height: 3px;
			border-radius: 2px;
			margin:0 3px;
			button{
				width: 30px;
				height: 3px;
				border-radius: 2px;
				background: rgba(255,255,255,.7);
				&::before{
					width: 30px;
					height: 3px;
					border-radius: 2px;
					color: transparent;
				}
				padding:3px;
			}
		}
		li.slick-active{
			button{
				background: #FFF;
			}
		}
	}
}
.slick-dotted.slick-slider{
	margin-bottom: 0px;
}
/*------group-section-------*/
.group-section{
	.image{
		position: relative;
		padding-top: 68%;
		display: block;
		img{
			position: absolute;
			top: 0;
			right:0;
			left: 0;
			transition: .4s ease-in-out;
		}	
	}
}
/*----------sponsers------------*/
.sponsers-section{
	.slick-slide{
		padding:15px;
		overflow:hidden;
		.item{
			&:hover{
				box-shadow:0 1px 7px 2px rgba(0, 0, 0, 0.2);
			}
			img{
				height: 85px!important;
				object-fit: contain;
				margin:auto;
			}
		}
	}
}
/*-----------projects section-----*/
.projects-section{
	.button.slick-arrow{
		position: absolute;
		border-radius: 100%;
		width: 40px;
		height: 40px;
		bottom: -50px;
		background-color: $secondary;
		border:0px;
		border-color: transparent;
	}
	.button.prev-btn.slick-arrow{
		right: 10px;
	}
	.button.next-btn.slick-arrow{
		right: 65px;
	}
	.slick-arrow.slick-disabled{
		background-color: #D7DCE2;
	}
	.slick-slide{
		padding:8px;
	}
	.card{
		box-shadow: none;
		position: relative;
		background: #FFF;
		overflow:hidden;
		.card-content{
			min-height: 11rem;
		}
		.card-hover{
			transition: .4s ease-in-out;
			position: absolute;
			bottom: 0px;
			right: 0;
			left: 0;
			width:0px;
			height: 3px;
			background-color: $secondary;
			margin:auto;
		}
		&:hover > .card-hover {
			width: 100%;
			transition: .4s ease-in-out;
		}
		&:hover > .card-image a .image img{
			transform: scale(1.1);
			transition: .4s ease-in-out;
		}
		.card-image{
			overflow:hidden;
			.image{
				position: relative;
				padding-top: 75%;
				display: block;
				img{
					position: absolute;
					top: 0;
					right:0;
					left: 0;
					transition: .4s ease-in-out;
					border-radius: 0px;
				}	
			}
		}
	}
}
/*------------capabilities-------*/
.card.capabilities-card{
	position: relative;
	.card-hover{
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		right: 0px;
		top: 0;
		border: 2px dashed $secondary;
		opacity: 0;
		transition: .4s ease-in-out;
	}
	&:hover > .card-hover{
		transition: .4s ease-in-out;
		opacity: 1;
	}
	.card-cont{
		transition: .3s ease-in-out;
		&:hover{
			transform: translate(-15px,-15px);
			transition: .3s ease-in-out;
			display: block;
			background: #FFF;
		}
	}
	.image{
		position: relative;
		padding-top: 35%;
		display: block;
		img{
			position: absolute;
			top: 0;
			right:0;
			left: 0;
			transition: .4s ease-in-out;
		}	
	}
}
.is-overlay{
		background-color: rgba(6, 132, 241, 0.37);
		display: table;
		vertical-align: middle;
		width: 100%;
		height: 100%;
		h2{
			display: table-cell;
			height: 100%;
			width: 100%;
			vertical-align: middle;
		}
	}
/*------------footer-------------*/
.our-group{
	position: fixed;
	left: 0;
	bottom: 30px;
	width: 150px;
	height: 55px;
	line-height: 55px;
	z-index: 1;
	text-align: center;
	transition: .4s ease-in-out;
	a{
		display: block;
		width: 100%;
		height: 100%;
		color: $secondary;
		i{
			animation: slide_left_right 2s ease-in-out infinite alternate both;
		}
	}
}
.our-group.scroll{
	bottom:350px;
	transition: .4s ease-in-out;
}
.scroll-top{
	width: 50px;
	height: 50px;
	line-height: 50px;
	position: fixed;
	right: 8%;
	bottom: 0px;
	z-index: 10;
	text-align: center;
	a{
		display: block;
		width: 100%;
		height: 100%;
		color: white;
		background-color: transparent;
		i{
			animation: slide_down_up 1s ease-in-out infinite alternate both;
		}
	}
}
.scroll-top.scroll{
	bottom:325px;
	transition: .4s ease-in-out;
}
.footer{
	margin-top: 5rem;
	padding: 3rem 0px 2rem;
	.footer-logo{
		img{
			width:42px;
			height: 63px;
		}
	}
	.field.has-addons{
		border:1px solid #FFF;
		height: 50px;
		input{
			background-color: transparent;
			border-color: transparent;
			color: white;
		}
		input, .button{
			height: 100%;
			border-radius: 0;
		}
	}
	.socials{
		display: flex;
		li{
			margin:0 5px;
			a{
				color: white;
				text-align: center;
				background-color: transparent;
				font-size: 1.5rem;
				&:hover{
					background-color:transparent;
					color:$secondary;
				}
			}
		}
	}
}
.facts-section{
	p{
		color: white!important;
	}
}