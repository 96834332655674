.inner-header{
	position: relative;
	height: 440px;
	figure{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
		img{
			width: 100%!important;
			height: 100%;
		}
	}
	&::after{
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.00) 100%);
	}
	.hero-body{
		.title{
			z-index: 1;
			position: relative;
		}
	}
}
.our-values-section{
	.card{
		border: 1px solid #D7DCE2;
		&:hover{
			border:2px solid $secondary;
		}
		.icon-card{
			position: absolute;
			right: 0;
			left: 0;
			background: white;
			top: -40px;
			width: 80px;
			height: 80px;
			line-height: 80px;
			margin:auto;
			box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
			img{
				width: 50px;
				height: 50px;
			}
		}
		.card-content{
			margin-top: 3.5rem;
		}
	}
}
.about-section{
	.content{
		border-left: 3px solid $secondary;
	}
	.image{
		padding-top: 60%;	
	}
}
/*----------------services---------*/
.services-section{
	.card{
		border: 1px solid #D7DCE2;
		box-shadow: none;
		p, ul{
			color: $primary!important;
		}
		&:hover{
			border:2px solid $secondary;
			box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.15);
		}
		ul{
			list-style: disc;
			padding-left: 1rem;
			li{
				padding-top:1rem;
			}
		}
	}
}
.news-loader{
	margin:auto;
	width: 70px;
	height: 70px;
	display: block;
	img{
		width: 100%;
		height: 100%;
	}
}
/*--------------contact section-------*/
.contact-section{
	background-image: url('/front/images/contact-bg.png');
	background-repeat: no-repeat;
	background-position: center;
	padding:3rem;
	position: relative;
	overflow:hidden;
	&::after{
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('/front/images/contact-after.svg');
		background-repeat: no-repeat;
	}
}
.client-reference{
	.card{
		box-shadow: none;
		position: relative;
		.media-left{
			.is-120x120{
				width: 120px;
				height: 120px;
				border-radius: 100%;
				overflow:hidden;
			}
		}
		.btn-container{
			position: absolute;
			top: 100px;
			right: 20px;
		}
	}
}
/*------------contact page--------*/
.office-section{
	.card{
		box-shadow: none;;
		border:1px solid #D7DCE2;
		.media{
			.media-left{
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background-color: $accent;
				color: $secondary;
			}
		}
	}
}
/*-----------projects page----------*/
.filter-buttons{
	.button.is-checked{
		background-color:$red;
		color: white!important;
		box-shadow: 0px 12px 20px 0px rgba(251, 42, 28, 0.30);
		transition: .4s ease-in-out;
	}
	.button{
		border:0px;
		transition: .4s ease-in-out;
		&:hover, &:focus{
			background-color:$red;
			color: white!important;
			box-shadow: 0px 12px 20px 0px rgba(251, 42, 28, 0.30);
			transition: .4s ease-in-out;
		}
	}
}
.VuePagination {
	margin-top:4rem;
	display: flex;
	.page-item.VuePagination__pagination-item-next-page, .page-item.VuePagination__pagination-item-prev-page{
		.page-link{
			/*border:1px solid #ccc;
			display: block;
			color: $primary;
			border-radius: 10px;*/
		}
	}
	.page-item{
		width: 40px;
		height: 40px;
		line-height: 40px;
		margin:0 .2rem;
		.page-link{
			display: block;
			color: $primary;
			border-radius: 10px;
		}
		.page-link.active{
			border:1px solid #ccc;
		}
	}
}
.products-section{
	.card{
		.is-overlay{
			opacity: 0;
			transition: .4s ease-in-out;
			position: absolute;
			top: -100%;
			right: 0;
			left: 0;
		}
		.card-content{
			min-height: auto;
		}
		.button.is-transparent{
			background-color: transparent;
			border:0px;
			margin-top:30%;
		}
		&:hover{
			.card-image{
				.is-overlay{
					opacity: 1;
					transition: .4s ease-in-out;
					top: 0;
				}
			}
		}
	}
	.modal-card{
		figure{
			position: relative;
			padding-top: 43%!important;
			display: block;
			img{
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				max-height: 100%;
				border-radius: 0px;
			}
		}
	}
}