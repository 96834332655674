// Body
$body-bg: #FFF;
$input-placeholder-color: #2F3740;
//general
$primary: #2F3740;
$primary-dark:#0B1829;
$secondary:#0684F1;
$accent:#E6F3FE;
$accent1:#F8F9FC;
$green:#75B963;
$red:#FB2A1C;
$gold:#be9750;
$grey-dark:#CCCCCC;
$grey:#555E68;
$light-grey:#f2f0f0;
$grey-lighter:#fbfafa;
$grey-light2:#eeecec;




