@media screen and (max-width: 1920px){
	.slideshow{
		.slick-dots{
			left:17%;
		}
	} 
}
@media screen and (max-width: 1600px){
	.slideshow{
		.slick-dots{
			left:11%;
		}
	}
}
@media screen and (max-width: 1471px){
	.slideshow{
		.slick-dots{
			left:7%;
		}
	}
}
@media screen and (max-width: 1366px){
	.slideshow{
		.slick-dots{
			left:11%;
		}
	}
}

@media screen and (max-width: 1280px){
	
}
@media screen and (max-width: 1200px){
	
}

@media screen and (max-width: 1087px){
	.header {
		.navbar-end{
			margin-left: .5rem;
		}
	}
}
@media screen and (max-width: 1024px){
	
}
@media screen and (max-width: 768px){
	.container{
		padding-right: 25px;
		padding-left: 25px;
	}
	.slideshow{
		.slick-dots{
			left:14%;
		}
	}
	.header {
		.navbar-item.logo {
			img{
				max-height: 55px;
			} 
		}
	}
	.navbar-burger{
		color: $red;
		font-weight: bold;
		height: 4.3rem;
		width: 1.25rem;
		&:hover{
			background-color: transparent;
			color: $secondary;
		}
	}
}
@media (max-width: 640px) {
	.group-section{
		.image{
			padding-top: 50%;
		}
	} 
	.section-padding{
		padding:10px 0px;
	}
	.projects-section.mt-3{
		margin-top:0px!important;
	}
	.projects-section .button.slick-arrow{
		bottom:-30px;
	}
	.button.is-size-5{
		font-size: 13px!important;
	}
	.ml-3{
		margin-left: 0.3rem !important;
	}
	.mr-3{
		margin-right: 0.3rem !important;
	}
	.is-size-1{
		font-size: 30px!important;
	}
	.is-size-2{
		font-size: 22px!important;
	}
	.is-size-3{
		font-size:19px!important;
	}
	.is-size-4{
		font-size: 17px!important;
	}
	.is-size-5{
		font-size: 15px!important;
	}
	.details-section .mt-3{
		margin-top: 0px!important;
	}
}
@media screen and (max-width: 520px){
	.slideshow .slick-dots{
		left: 20%;
	}
}
@media (max-width: 480px) {


}
@media (max-width: 420px){
	.slideshow .slick-dots{
		left: 25%;
	}
}
@media (max-width: 414px){

}
@media (max-width: 375px){

}
@media (max-width: 360px){

}
@media (max-width: 320px) {

}

