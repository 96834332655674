::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background-color: #FFF;
	border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #CCC; 
	border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #CCC; 
}
body{
	font-size: 16px;
	font-family: 'Cairo', sans-serif;
	background-color: $body-bg;
	max-width: 100%;
}
a{
	&:focus{
		outline: none;
	}
}
.no-pointer{
	cursor: default;
}
.image-contain{
	object-fit: contain;
}
.image-cover{
	object-fit: cover;
}
.is-size-1{
	font-size: 40px!important;
}
.is-size-2{
	font-size: 27px!important;
}
.is-size-3{
	font-size: 24px!important;
}
.is-size-4{
	font-size: 20px!important;
}
.is-size-5{
	font-size: 18px!important;
}
.is-size-6{
	font-size: 14px!important;
}
.has-text-white{
	color: white!important;
}
.has-text-grey{
	color: $grey!important;
}
.has-text-red{
	color: $red!important;
}
.line-height-1{
	line-height: 2rem;
}
.full-width{
	width: 100%!important;
}
.full-height{
	height:100%!important;
}
/**********display******/
.display-table{
	display: table!important;
}
.display-table-cell{
	display: table-cell;
}
.vertical-align{vertical-align: middle;}
.hvr-rectangle-out::before{
	background: $secondary;
	color: #FFF;
}
.hvr-rectangle-out{
	&:hover{
		color: white!important;
	}
}
.section-padding{
	padding:30px 0px;
}
.has-background-secondary{
	background-color: $secondary;
}
.has-background-accent{
	background-color: $accent!important;
}
.has-background-accent1{
	background-color: $accent1!important;
}
.height-270{
	height: 270px!important;
}
.height-400{
	height: 400px!important;
}
.height-90vh{
	height: 90vh;
}
.radius-15{
	border-radius: 15px;
}
.position-relative{
	position: relative;
}
figure{
	img{
		width:100%;
		height: 100%!important;
	}
}
.has-text-secondary{
	color:$secondary!important;
}
.has-text-transparent{
	color: #2b2929d6!important;
}
.basic-title{
	position: relative;
	z-index: 1;
	&::before{
		content: "";
		position: absolute;
		top: -8px;
		left: -10px;
		width: 50px;
		height: 50px;
		background-image: url("/front/images/vector.svg");
		background-repeat: no-repeat;
		background-size: contain;
		z-index: -1;
	}
}
.more{
	color: $secondary;
	font-size: 16px;
	font-weight: 600;
	text-decoration-line: underline;
	text-transform: uppercase;
	line-height: 4rem;
}
footer input::placeholder {
	color: white!important;
}

.font-weight-bold{
	font-weight: 700;
}

.slide_left_right {
	-webkit-animation: slide_left_right 1.7s ease-in-out infinite alternate both;
	animation: slide_left_right 1.7s ease-in-out infinite alternate both;
}
.slide_down_up {
	-webkit-animation: slide_down_up 1.7s ease-in-out infinite alternate both;
	animation: slide_down_up 1.7s ease-in-out infinite alternate both;
}
/* ------- */
@-webkit-keyframes slide_left_right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
}

@keyframes slide_left_right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
}
/* ------- */
@-webkit-keyframes slide_down_up {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(30%);
		transform: translateY(30%);
	}
}

@keyframes slide_down_up {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(30%);
		transform: translateY(30%);
	}
}
/*-----margins-----*/
.m-t92{
	margin-top: 92px!important;
}
.mt-7{
	margin-top:5rem!important;
}
.main-wrapper{
	min-height: 400px;
}
.px-8{
	padding-right: 7rem!important;
	padding-left: 7rem!important;
}
.z-index{
	z-index: 1;
}
.button.is-info{
	padding-left:3em;
	padding-right:3em;
	background-color: $secondary;
	border-color:$secondary;
	border-radius: 8px;
	overflow:hidden;
	&::before {
		background: $primary;
		border-color:$primary;
	}
	&:hover{
		box-shadow: none;
		transition: .4s ease-in-out;
	}
}
.shadow-button{
	box-shadow:(0px 15px 25px rgba(6, 132, 241, 0.30));
	transition: .4s ease-in-out;
}
.button.is-success{
	width: 35px;
	height: 35px;
	line-height: 35px;
	border-radius: 100%;
	background-color: $green;
	color: white;
}
.d-inline-block{
	display: inline-block;
}
hr{
	height: 2px;
	background-color: $grey-dark;
}
.select select, .textarea, .input{
	border-color: #D7DCE2;
	box-shadow: none;
}
.control.has-icons-left .icon, .control.has-icons-right .icon, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .file-cta, .file-name, .select select, .textarea, .input, .button{
	height: 3em;
}
p{
	color: $primary;
}
.has-opacity{
	opacity: .8;
}
a.has-text-primary:hover, a.has-text-primary:focus{
	color: $red!important;
}
.modal-card{
	width: 70%;
	border-radius: 15px;
	.modal-card-head{
		.delete{
			margin-left: auto;
			background-color: $primary;
		}
	}
}
.has-border{
	border:1px solid #D7DCE2;
}